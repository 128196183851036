<template>
  <div class="wrap">
    <NavBar
      :fixed="true"
      :title="$t('mine.serviceTools.inviteTask')"
      left-arrow
      placeholder
      @click-left="$router.go(-1)"
    />
    <JList
      list
      class="list_wrap"
      tabBottomBorder
      :request="false"
      customList
    ></JList>
  </div>
</template>

<script>
import { NavBar } from "vant";
import JList from "@/components/j-list.vue";
export default {
  components: { NavBar,JList },
};
</script>

<style lang="less" scoped>
.wrap {
  height: 100vh;
  background: #f5f5f5;
}
</style>